import React, { useState, useEffect } from 'react';
import { camelCase } from './utils/helpers';
import { StatusCheckbox } from './StatusUxSupport';

const DataTable = ({ data, onCheckedItemsChange }) => {

    const [checkAll, setCheckAll] = useState(false);
    const [checkedItems, setCheckedItems] = useState({});

    // Update parent with the current checked items
    useEffect(() => {
        onCheckedItemsChange(checkedItems);
    }, [checkedItems, onCheckedItemsChange]);

    if (!data || data.length === 0) {
        return <p>No data available</p>;
    }

    // Handler to toggle individual checkboxes
    const handleCheckboxChange = (event,id) => {
        setCheckedItems((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    // Handler to toggle the "Check All" checkbox
    const handleCheckAllChange = (event) => {
        const newCheckAll = !checkAll;
        setCheckAll(newCheckAll);

        const updatedCheckedItems = {};
        data.forEach((item) => {
            updatedCheckedItems[item.id] = newCheckAll;
        });

        setCheckedItems(updatedCheckedItems);
    };

    const exclude = ['id', 'status', 'company_id', 'last_updated_timestamp', 'last_scan_timestamp','items','cred_id',
                    'spf_enabled','dmarc_enabled','spf_strong','dmarc_strong'];

    // Get the keys from the first object in the array as table headers
    const headers = Object.keys(data[0]);

    return (
        <table className="finding-table">
            <thead>
                <tr>
                    <th valign="middle" scope="row" className="status-toggle">
                        <div className="form-check">
                            <input className="form-check-input childCheckbox"
                                type="checkbox"
                                value=""
                                name="checkAll"
                                id="checkAll"
                                checked={checkAll}
                                onChange={(event) => handleCheckAllChange(event)}
                            />
                            <label class="form-check-label"></label>
                        </div>
                    </th>
                    <th className="status-text">
                        Status
                    </th>
                    {headers.map((header) => (
                        !exclude.includes(header) && (<th key={header} className="dynamic-column">{camelCase(header)}</th>)
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((row, index) => (
                    <tr key={index} className={row.status === 'accepted' ? 'ghost-text' : ''}>
                        <StatusCheckbox id={row.id} checkedItems={checkedItems} onCheckboxChange={handleCheckboxChange} />
                        <td className="status-text">
                            {camelCase(row.status)}
                        </td>
                        {headers.map((header) => (
                            !exclude.includes(header) && (
                            <td key={header} className="dynamic-column">
                                {row[header]}
                            </td>)
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default DataTable;