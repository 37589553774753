// apiClient.js
import axios from 'axios';

const token = localStorage.getItem('token');
const apiHost = window.env.API_HOST;

// Create an Axios instance with default settings
const apiClient = axios.create({
  baseURL: apiHost, // Base URL for your API
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
  },
});

// General REST handler function
const restHandler = async (method, url, data = null, headers = {}) => {
  try {
    const response = await apiClient({
      method,
      url,
      data,
      headers: {
        ...apiClient.defaults.headers,
        ...headers, // Allow overriding or adding custom headers
      },
    });
    return response.data; // Return the response data
  } catch (error) {
    console.error('API Error:', error);
    throw error.response ? error.response.data : new Error('API request failed');
  }
};

export default restHandler;