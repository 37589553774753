import React, { useEffect, useState } from 'react';
import restHandler from './apiClient';
import './Modal.css'; // Import CSS for styling
import DataTable from './DataTable';
import './Finding.css';
import { camelCase, showIssues } from './utils/helpers';
import { StatusMenu } from './StatusUxSupport';

const apiHost = window.env.API_HOST;
const token = localStorage.getItem('token');

const HeaderBlock = ({company,topic,count}) => (
  <div className="col-xl-11" styles="padding:15px 30px;" id="theModalLabel">
    {company && (<h4 className="card-title">{company}</h4>)}
    <h5 className="modal-title fs-30">
      <i className="mdi mdi-alert fs-30 text-danger mr-10"></i>
      {camelCase(topic)} - {showIssues(count)} 
    </h5>
  </div>
)

const FindingModal = ({ id, company, topic }) => {

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);    
  const [reloadData, setReloadData] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const [checkedItems, setCheckedItems] = useState({});

  const handleCheckedItemsChange = (newCheckedItems) => {
    setCheckedItems(newCheckedItems);
  };

  const forceDataReload = () => {
    setData(null); // clear old data
    setReloadData(true); // a state flag
  }

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleItemClick = (event, item) => {
    handleSubmit(event, item); // Trigger form submission when an item is clicked
  };

  useEffect(() => {

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    // Attach the event listener only if the modal is open
    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }

    const fetchData = async () => {
        try {
            const result = await restHandler('GET', '/api/company_findings/' + topic + '/' + id);
            setData(result);
        } catch (error) {
            setError(error.message || 'Error fetching data');
        }
    };

    fetchData();

    // Set reloadData to false after fetching
    setReloadData(false);

    // Cleanup event listener on component unmount or when modal is closed
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [id,topic,isOpen, reloadData]);

  const handleSubmit = async (event, item) => {
    event.preventDefault();
    // Handle form submission logic here

    const itemids = Object.entries(checkedItems)
    .filter(([key, value]) => value === true)
    .map(([key]) => key);

    console.log('itemids:', itemids)

    if (itemids.length === 0) return;

    // Send the checked values to the backend
    try {
      const response = await fetch(apiHost + '/api/submit-status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          topic: topic,
          status: item,
          itemids: itemids
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      console.log('Server response:', responseData);

      // Handle server response here
    } catch (error) {
      console.error('Error submitting data:', error);
      // Handle error here
    }
    // After form submission, set reloadData to true
    forceDataReload();
  };

  //  if (!isOpen) return null; // Don't render if the modal is closed

  if (!data) {
    return <div className="spinner-border" role="status">
      <span className="sr-only"></span>
    </div>
  }

  return (
    <div>
      <div className="flex-shrink-0">
        <button onClick={openModal}
          style={{ cursor: 'pointer' }}
          className="fs-16 mb-0 text-truncate w-xs w-100 bg-light p-2 rounded text-center border-0 tooltip">
            <span className="tooltiptext">This is the custom help text</span>
        </button>
      </div>

      {isOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="window-container" onClick={(e) => e.stopPropagation()}>
            <form onSubmit={handleSubmit} className="modal-form">
              <input type="hidden" name="type" id="type" value={topic} />
              <div className="window-header">
                <div className="dropdown">
                  <StatusMenu topic={topic} onClick={handleItemClick} />
                </div>
                <button onClick={closeModal} className="close-button">
                  &times;
                </button>
                <HeaderBlock company = {company} topic={topic} count={data.data.length} />
              </div>
              <div className="table-container">
                <DataTable onCheckedItemsChange={handleCheckedItemsChange} data={data.data} />
              </div>
              <div className="form-actions">
                {false && (<button type="submit" className="submit-button">
                  Submit
                </button>)}
                <button type="button" className="btn btn-primary" onClick={closeModal}>
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default FindingModal;