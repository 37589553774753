import React from 'react';
import { camelCase, showIssues } from './utils/helpers';
import FindingModal from './FindingModal';
import Avatar from './Avatar';

const Summarytopic = ({ data, topic, count }) => {

    const id = data.id
    const company = data.name;

    return (
        <div class="col">
            <div class="sale-social-box">
                <div class="d-flex align-topics-center">
                    <div class="flex-shrink-0">
                        <Avatar topic={topic} count={count} />
                    </div>
                    <div class="flex-grow-1 ms-3 overflow-hidden pl-10">
                        <h5 class="fs-16 mb-1">{camelCase(topic)}</h5>
                        <p class="text-muted mb-0">{showIssues(count)}</p>
                    </div>
                    <FindingModal id={id} company={company} topic={topic} />
                </div>
            </div>
        </div>
    );
}

export default Summarytopic;
