import React from "react";
import Layout from "./Layout";

const EULA = ({ onAccept }) => {
    return (
        <>
         <Layout showNav={true} />
<div class="page-content">
    <div class="container">
        <div sstyle="width: 80%; margin: auto; font-family: Source Sans Pro, sans-serif; font-size:16px; color:#313131;">
            <p>Last updated: May 2024</p>

            <p>This Agreement is a legally binding agreement between you as the Customer ("Customer" or "you" or "your")
                and TARA, LLC for the use of our software application known as TARA Cyber Threat Analytics ("TARA CTA"
                or "CTA" or "Software" or "Application"). Please read this End-User License Agreement carefully before
                clicking the "I Agree" button or using TARA.</p>
            <p>By clicking the "I Agree" button, or using CTA, you are agreeing to be bound by the terms and conditions
                of this Agreement.</p>
            <p>If you do not agree to the terms of this Agreement, do not click on the "I Agree" button, and do not use
                CTA.</p>
            <p><strong>1. Definitions</strong></p>
            <p><em>1.1 <u>Content Updates.</u></em> means content used by CTA, which is updated from time to time, including but not
                limited to updated vulnerability signatures for vulnerability assessment products and exploits for
                penetration testing products.</p>
            <p><em>1.2 <u>Documentation.</u></em> means the documentation for the Software or services generally supplied by TARA to
                assist its customers in their use of the Software, including user and system administrator guides,
                manuals, and the functionality specifications.</p>
            <p><em>1.3 <u>Maintenance and Support Term.</u></em> means the period in which Customer is entitled to receive Content
                Updates, as applicable, and support services from TARA, including all updates, enhancements, bug fixes
                and new releases there to that TARA makes generally available to its customers.</p>
            <p><em>1.4 <u>Software Term.</u></em> means the period in which Customer is authorized to utilize the Software. Each Software
                Term shall be listed on the applicable Order Form and shall commence on the date of delivery of the
                Software.</p>
            <p><em>1.5 <u>Volume Limitations.</u></em> means the capacity indicated in the Order Form, including, as applicable, the
                number of IPs, organizations, applications, data, plugins, and named individual users of the Software.
            </p>
            <p><em>1.6 <u>Order Form.</u></em> means the document submitted by you that describes the scope and term for the CTA
                Subscription.</p>
            <p><strong>2. Software Licenses</strong></p>
            <p><em>2.1 <u>License to Products.</u></em> TARA hereby grants to Customer, during the applicable Software Term only, a
                non-exclusive, non-transferable license to use the Software (in object code only) listed in the
                Statement of Work within the Volume Limitations, for your internal business purposes only, and solely in
                accordance with the applicable Documentation, to use CTA solely for monitoring cyber risk on internet
                facing resources strictly in accordance with the terms of this Agreement.</p>
            <p><em>2.2 <u>Ownership of Software.</u></em> CTA is protected under various intellectual property laws. TARA retains all
                right, title, and interest in and to the Documentation, Software, Content Updates and in all copies,
                modifications and derivative works thereto including, without limitation, all rights to patent,
                copyright, trade secret, trademark, and other proprietary or intellectual property rights.</p>
            <p><em>2.3 <u>Customer Systems.</u></em> Customer represents and warrants that it has the appropriate authorizations from
                the owner of the networks, systems, IP addresses, assets, and/or hardware which it targets, scans,
                monitors, or tests with the Software.</p>
            <p><em>2.4 <u>Restrictions.</u></em> The Software may only be used for the purposes of good-faith testing, investigation,
                and/or correction of security flaws, exposures, or vulnerabilities in order to advance the security or
                safety of devices, machines, or networks of those who use such devices, machines, or networks. You agree
                you will not, and will not permit or authorize third parties to: (i) reproduce, modify, translate,
                enhance, decompile, disassemble, reverse engineer, create derivative works of the Software, or merge the
                Software into another program; (ii) resell, rent, lease, or sublicense the Software or access to it,
                including use of the Software for timesharing or service bureau purposes; (iii) circumvent or disable
                any security or technological features or measures in the Software; nor (iv) use the Software in order
                to build a competitive product or service, for competitive analysis, or to copy any ideas, features,
                functions, or graphics of the Software.</p>
            <p><strong>3. Fees and Payment Terms</strong></p>
            <p>3.1 If you are purchasing the Software through a CTA authorized reseller, then the fees shall be as set
                forth between you and reseller and the applicable fees shall be paid directly to the reseller and
                Section 3.2 shall not apply.</p>
            <p>3.2 You agree to pay the fees, charges, and other amounts in accordance with the Order Form. All fees are
                nonrefundable, unless otherwise stated herein. You will be responsible for remitting all taxes levied on
                any transaction under this Agreement, including, without limitation, all federal, state, and local sales
                taxes, levies and assessments, and local withholding taxes in Customer's jurisdiction, if any,
                excluding, however, any taxes based on TARA's income.</p>
            <p><strong>4. Limited Warranty</strong></p>
            <p><em>4.1 <u>Software Warranty.</u></em> TARA warrants that for a period of ninety days following the initial access of
                Software by Customer the Software will conform, in all material respects, with the applicable
                Documentation. TARA makes no warranty regarding third party features or services. For a breach of the
                above warranty, TARA will, at no additional cost to Customer, use commercially reasonable efforts to
                provide remedial services necessary to enable the Software to conform to the warranty. If TARA is unable
                to restore such functionality, Customer shall be entitled to terminate the applicable Order Form and
                receive a pro rata refund of the fees paid. Customer will provide TARA with a reasonable opportunity to
                remedy any breach and reasonable assistance in remedying any defects. The remedies set out in this
                subsection are Customer's sole remedies for breach of the above warranty.</p>
            <p><em>4.2 <u>Disclaimer.</u></em> <strong sstyle="font-size:.95em;">TARA DOES NOT REPRESENT THAT THE SOFTWARE WILL BE
                    UNINTERRUPTED, ERROR-FREE, OR WILL MEET CUSTOMER'S REQUIREMENTS. EXCEPT FOR THE WARRANTY ABOVE, TARA
                    MAKES NO OTHER WARRANTIES OR REPRESENTATIONS, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION,
                    THOSE OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT OF THIRD PARTY
                    RIGHTS. TARA MAKES NO WARRANTY THAT ALL SECURITY RISKS OR THREATS WILL BE DETECTED BY USE OF THE
                    SOFTWARE OR THAT FALSE POSITIVES WILL NOT BE FOUND.</strong></p>
            <p><strong>5. Limitation of Liability</strong></p>
            <p><em>5.1 <u>Limitation on Damages.</u></em> NEITHER PARTY WILL BE LIABLE UNDER THIS AGREEMENT FOR LOST REVENUES OR
                INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES, EVEN IF THE PARTY KNEW OR
                SHOULD HAVE KNOWN THAT SUCH DAMAGES WERE POSSIBLE.</p>
            <p><em>5.2 <u>Limitation on Amount of Liability.</u></em> WITH THE EXCEPTION OF ITS INDEMNITY OBLIGATIONS UNDER SECTION 9.1
                BELOW, TARA WILL NOT BE LIABLE TO CUSTOMER OR ANY THIRD PARTY UNDER THIS AGREEMENT FOR MORE THAN THE
                AMOUNT PAID OR PAYABLE BY YOU TO TARA HEREUNDER DURING THE TWELVE MONTHS PRIOR TO THE EVENT GIVING RISE
                TO LIABILITY.</p>
            <p><strong>6. Volume Limitations</strong></p>
            <p><em>6.1 <u>Usage Verification.</u></em> You understand and acknowledge that the Software may track and/or enforce its
                Volume Limitations. Additionally, upon TARA's written request, Customer shall provide TARA with a signed
                certification verifying that the Software is being used in accordance with this Agreement. Customer will
                permit TARA to review and verify Customer's records, and use of the Software for compliance with the
                terms and conditions of this Agreement. Any such review shall be scheduled at least ten days in advance,
                shall be conducted during normal business hours electronically or at Customer's facilities, and shall
                not unreasonably interfere with Customer's business activities.</p>
            <p><em>6.2 <u>Overscanning.</u></em> In the event that usage verification reveals the Software is being used in excess of
                the Volume Limitations, Customer shall be liable for all the fees for such excess usage at TARA' then
                current list rates, or as otherwise set forth on the Order Form.</p>
            <p><strong>7. Confidentiality</strong></p>
            <p><em>7.1 <u>Confidential Information.</u></em> During the term of this Agreement, each party will regard any information
                provided to it by the other party and designated in writing as proprietary or confidential as
                confidential ("Confidential Information"). Confidential Information shall also include information which
                a reasonable person familiar with the disclosing party's business and the industry in which it operates
                would know is of a confidential or proprietary nature. A party will not disclose the other party's
                Confidential Information to any third party without the prior written consent of the other party, nor
                make use of any of the other party's Confidential Information except in its performance under this
                Agreement. Each party accepts responsibility for the actions of its agents or employees and shall
                protect the other party's Confidential Information in the same manner as it protects its own
                Confidential Information, but in no event with less than reasonable care. The parties expressly agree
                that the terms and pricing of this Agreement are Confidential Information. A receiving party shall
                promptly notify the disclosing party upon becoming aware of a breach or threatened breach hereunder and
                shall cooperate with any reasonable request of the disclosing party in enforcing its rights.</p>
            <p><em>7.2 <u>Exclusions.</u></em> Information will not be deemed Confidential Information if such information: (i) is known
                prior to receipt from the disclosing party, without any obligation of confidentiality; (ii) becomes
                known to the receiving party directly or indirectly from a source other than one having an obligation of
                confidentiality to the disclosing party; (iii) becomes publicly known or otherwise publicly available,
                except through a breach of this Agreement; or (iv) is independently developed by the receiving party
                without use of the disclosing party's Confidential Information. The receiving party may disclose
                Confidential Information pursuant to the requirements of applicable law, legal process or government
                regulation, provided that, unless prohibited from doing so by law enforcement or court order, the
                receiving party gives the disclosing party reasonable prior written notice, and such disclosure is
                otherwise limited to the required disclosure.</p>
            <p><strong>8. Termination</strong></p>
            <p><em>8.1 <u>Bankruptcy; Default.</u></em> This Agreement may be terminated: (i) by either party if the other party is
                adjudicated as bankrupt, or if a petition in bankruptcy is filed against the other party and such
                petition is not discharged within sixty days of such filing; or (ii) by either party if the other party
                materially breaches this Agreement and fails to cure such breach to such party's reasonable satisfaction
                within thirty days following receipt of written notice thereof.</p>
            <p><em>8.2 <u>Work Order.</u></em> Your license to use the Software shall also terminate upon the expiration of the
                applicable Work Order or upon cancellation of services. Upon any termination of this Agreement, all
                applicable licenses are revoked and you shall immediately cease use of CTA. Termination of this
                Agreement or a license granted hereunder shall not relieve you of obligations to pay all fees that have
                accrued or become payable by you under this Agreement.</p>
            <p><strong>9. Indemnification</strong></p>
            <p><em>9.1 <u>By TARA.</u></em> TARA will indemnify, defend, and hold harmless Customer from and against all liabilities,
                damages, and costs (including settlement costs and reasonable attorneys' fees) arising out of a third
                party claim that the Software infringes or misappropriates any intellectual property right of such third
                party. Notwithstanding the foregoing, in no event shall TARA have any obligations or liability under
                this Section arising from: (i) use of any Software in a manner not anticipated by this Agreement or in
                combination with materials not furnished by TARA; or (ii) any content, information or data provided by
                Customer or other third parties. If the Software is or is likely to become subject to a claim of
                infringement or misappropriation, then TARA will, at its sole option and expense, either: (i) obtain for
                the Customer the right to continue using the Software; (ii) replace or modify the Software to be
                non-infringing and substantially equivalent to the infringing Software; or (iii) if options (i) and (ii)
                above cannot be accomplished despite the reasonable efforts of TARA, then TARA may terminate Customer's
                rights to use the infringing Software and will refund pro-rata any prepaid fees for the infringing
                portion of the Software. THE RIGHTS GRANTED TO CUSTOMER UNDER THIS SECTION 9.1 SHALL BE CUSTOMER'S SOLE
                AND EXCLUSIVE REMEDY FOR ANY ALLEGED INFRINGEMENT BY THE SOFTWARE OF ANY PATENT, COPYRIGHT, OR OTHER
                PROPRIETARY RIGHT.</p>
            <p><em>9.2 <u>By End User.</u></em> You will indemnify, defend, and hold harmless TARA from and against all liabilities,
                damages, and costs (including settlement costs and reasonable attorneys' fees) arising out of a third
                party claim regarding your: (i) use of the Software in violation of applicable law; or (ii) breach of
                your obligations under this Agreement.</p>
            <p><strong>10. General Provisions</strong></p>
            <p><em>10.1 <u>Compliance with Law.</u></em> Each party agrees to comply with all federal, state and local laws and
                regulations including but not limited to export law, and those governing the use of network scanners,
                vulnerability assessment software products, encryption devices, user monitoring, and related software in
                all jurisdictions in which systems are scanned, scanning is controlled, or users are monitored.</p>
            <p><em>10.2 <u>Data Privacy.</u></em> You represent and warrant that you have obtained all necessary rights to permit CTA to
                process your data from and about you, including, without limitation, data from endpoints, servers, cloud
                applications, and logs.</p>
            <p><em>10.3 <u>Miscellaneous.</u></em> This Agreement shall be construed in accordance with and governed for all purposes by
                the laws of the State of Michigan each excluding its respective choice of law provisions and each party
                consents and submits to the jurisdiction and forum of the state and federal courts in the State of
                Michigan. This Agreement, along with any applicable Order Form constitute the entire agreement and
                understanding of the parties hereto with respect to the subject matter hereof and supersedes all prior
                agreements and undertakings, both written and oral;. This Agreement and each Work Order may not be
                modified except by a writing signed by each of the parties. In case any one or more of the provisions
                contained in this Agreement shall for any reason be held to be invalid, illegal, or unenforceable in any
                respect, such invalidity, illegality, or unenforceability shall not affect any other provisions of this
                Agreement, but rather this Agreement shall be construed as if such invalid, illegal, or other
                unenforceable provision had never been contained herein. You will not assign your rights or obligations
                hereunder without TARA's advance written consent. This Agreement shall be binding upon and shall inure
                to the benefit of the parties hereto and their permitted successors and permitted assigns. No waiver of
                any right or remedy hereunder with respect to any occurrence or event on one occasion shall be deemed a
                waiver of such right or remedy with respect to such occurrence or event on any other occasion.</p>
            <p><strong> 11. Modifications to Application</strong></p>
            <p>TARA reserves the right to modify, suspend or discontinue, temporarily or permanently, CTA or any service
                to which it connects, with or without notice and without liability to you.</p>
            <p><strong>12. Contact Information</strong></p>
            <p> If you have any questions about this Agreement, please contact us. <a
                    href="mailto:support@taracta.com">support@taracta.com</a></p>
        </div>

        <button onClick={onAccept}>Accept</button>
        <button onClick={onAccept}>Do Not Accept</button>
    </div>
</div>
</>);
};

export default EULA;
