import React, { useEffect, useState } from 'react';
import restHandler from './apiClient';
import { chartColor, camelCase, showIssues } from './utils/helpers';
import VulnerabilityModal from './VulnerabilityModal';
import { StatusMenu, StatusCheckbox } from './StatusUxSupport';
import Avatar from './Avatar';

const apiHost = window.env.API_HOST;
const token = localStorage.getItem('token');

const HeaderBlock = ({ company, topic, count, children }) => {
    return (
        <div className="col-xl-11 pad15">
            {company && (<h4 className="card-title">{company.name}</h4>)}
            <h5 className="modal-title fs-30">
                <Avatar topic='vuln' count='1' />
                {camelCase(topic)} - {showIssues(count)}
            </h5>
            {children}
        </div>
    );
}

const Ransomware = ({ data }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="dropdown">
            <button onClick={toggleDropdown} className="btn btn-soft-danger btn-sm dropdown-toggle">
                Exploited by Ransomware: {data.cves.length} / {data.host_count}
            </button>
            {isOpen && (
                <div className="dropdown-menu" style={{ display: 'block' }}>
                    {data.cves.map((item, itemIndex) => (
                        <a key={itemIndex} className="dropdown-item" href={`#${item}`}>
                            {item}
                        </a>
                    ))}
                </div>
            )}
        </div>
    );
}

const Danger = ({ flag }) => {
    if (flag) {
        return <i className="mdi mdi-alert fs-14 mb-0 text-danger"></i>;
    }
    return <></>;
}

const Badge = ({ score }) => {
    const badgeStyle = {
        backgroundColor: chartColor(score / 10),
    };
    return (<span className="badge" style={badgeStyle}>{score}</span>);
}

const Vulnerabilties = (props) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [expandedRows, setExpandedRows] = useState(new Set());
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);

    const [checkAll, setCheckAll] = useState(false);
    const [checkedItems, setCheckedItems] = useState({});

    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

    // Handler to toggle individual checkboxes
    const handleCheckboxChange = (event, id) => {
        setCheckedItems((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    // Handler to toggle the "Check All" checkbox
    const handleCheckAllChange = (event) => {
        const newCheckAll = !checkAll;
        setCheckAll(newCheckAll);

        const updatedCheckedItems = {};
        if (Array.isArray(data.data)) {
            data.data.forEach((item) => {
                item.hosts.forEach((host) => {
                    updatedCheckedItems[item.id + '|' + host.ip] = newCheckAll;
                });
            });
        }
        setCheckedItems(updatedCheckedItems);
    };

    const forceDataReload = () => {
        setData(null); // clear old data
        setCheckedItems({});
    }

    const handleSubmit = async (event, item) => {
        event.preventDefault();
        // Handle form submission logic here

        const topic = 'DbVulnerability';

        const itemids = Object.entries(checkedItems)
            .filter(([key, value]) => value === true)
            .map(([key]) => key);

        console.log('itemids:', itemids)

        if (itemids.length === 0) return;

        // Send the checked values to the backend
        try {
            const response = await fetch(apiHost + '/api/submit-status', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    topic: topic,
                    status: item,
                    itemids: itemids
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseData = await response.json();
            console.log('Server response:', responseData);

            // Handle server response here
        } catch (error) {
            console.error('Error submitting data:', error);
            // Handle error here
        }
        // After form submission, set reloadData to true
        forceDataReload();
    };

    const handleItemClick = (event, item) => {
        handleSubmit(event, item);
    };

    const openModal = (event, rowData) => {
        event.preventDefault(); // Prevent form submission
        setSelectedRowData(rowData);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedRowData(null);
    };

    const toggleRow = (event, id) => {
        event.preventDefault(); // Prevent form submission
        const newExpandedRows = new Set(expandedRows);
        if (newExpandedRows.has(id)) {
            newExpandedRows.delete(id);
        } else {
            newExpandedRows.add(id);
        }
        setExpandedRows(newExpandedRows);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await restHandler('GET', '/api/company_vulnerabilities/135015');
                if (JSON.stringify(result) !== JSON.stringify(data)) {
                    setData(result);
                }
            } catch (error) {
                setError(error.message || 'Error fetching data');
            }
        };

        fetchData();
    }, [data]);

    if (!data) {
        return <div className="spinner-border" role="status">
            <span className="sr-only"></span>
        </div>
    }

    const company = data.company;
    const items = data.data;
    const ransomware = data.ransomware;

    return (
        <div className="row">
            <div className="window-header">
                <div className="dropdown">
                    <StatusMenu topic="vulnerabilities" onClick={handleItemClick} />
                </div>
                <HeaderBlock company={company} topic="External Vulnerabilities" count={items.length}>
                    <div className="flex-shrink-0">
                        <Ransomware data={ransomware} />
                    </div>
                </HeaderBlock>
            </div>
            <form className="modal-form">
                <input type="hidden" name="type" id="type" value="DbVulnerability" />
                <div className="mt-4">
                    <div data-simplebar data-simplebar-auto-hide="false" data-simplebar-direction="rtl" styles="max-height: 375px; padding-right:15px;">
                        <div className="table-container">
                            <table className="vulnerability-table">
                                <thead>
                                    <tr>
                                        <th valign="middle" scope="row" className="status-toggle">
                                            <div className="form-check">
                                                <input className="form-check-input childCheckbox"
                                                    type="checkbox"
                                                    value=""
                                                    name="checkAll"
                                                    id="checkAll"
                                                    checked={checkAll}
                                                    onChange={(event) => handleCheckAllChange(event)}
                                                />
                                                <label className="form-check-label"></label>
                                            </div>
                                        </th>
                                        <th className="status-text">
                                            Status
                                        </th>
                                        <th width="74" className="centered-cell">Risk Score</th>
                                        <th scope="col" className="left-cell">CVE</th>
                                        <th width="22" scope="col" className="left-cell">IP Address</th>
                                        <th width="22" scope="col" className="left-cell">Domain(s)</th>
                                        <th width="74" scope="col" className="centered-cell">Assets</th>
                                        <th scope="col" className="centered-cell">Exploitation Status / Prediction</th>
                                        <th scope="col" className="centered-cell">Ransomware Related</th>
                                        <th width="33" scope="col" className="centered-cell">View</th>
                                    </tr>
                                </thead>
                                <tbody id="vuln-table-body">
                                    {items.map((item, itemIndex) => (
                                        <React.Fragment key={itemIndex}>
                                            <tr className={item.hosts[0].status === 'accepted' ? 'ghost-text' : ''}>
                                                <StatusCheckbox id={item.id + '|' + item.hosts[0].ip} checkedItems={checkedItems} onCheckboxChange={handleCheckboxChange} />
                                                <td className="status-text">
                                                    {camelCase(item.hosts[0].status)}
                                                </td>
                                                <td valign="top" className="centered-cell">
                                                    <Badge score={item.sv_score} />
                                                </td>
                                                <td valign="top" width="187">
                                                    <p id={item.cve} className="pb-0 mb-0">{item.cve}</p>
                                                </td>
                                                <td valign="top">
                                                    {item.hosts[0].ip}
                                                </td>
                                                <td valign="top">
                                                    {item.hosts[0].domains.join(', ')}
                                                </td>
                                                <td valign="top" className="centered-cell">
                                                    {item.hosts.length > 1 && (
                                                        <button onClick={(event) => toggleRow(event, itemIndex)}
                                                            className={`btn btn-primary v-toggle-row ${expandedRows.has(item.id) ? 'expanded' : 'collapsed'}`}>
                                                            {item.hosts.length} {expandedRows.has(itemIndex) ? '▲' : '▼'}
                                                        </button>)}
                                                </td>
                                                <td valign="top" width="245" className="centered-cell">
                                                    <Danger flag={item.exploited_in_wild} />
                                                    {item.exploitation_status} / {item.exploit_probablity}
                                                </td>
                                                <td valign="top" width="105" className="centered-cell">
                                                    {item.ransomware_related && (
                                                        <img width="16" height="16" src="/assets/images/ransomware.png" alt="Yes" />)}
                                                </td>
                                                <td valign="top">
                                                    <button onClick={(event) => openModal(event, item.cve)} style={{ cursor: 'pointer' }} className="fs-16 mb-0 text-truncate w-xs w-100 bg-light p-2 rounded text-center border-0">
                                                        <i className="mdi mdi-arrow-right text-dark align-middle"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            {expandedRows.has(itemIndex) &&
                                                item.hosts.slice(1).map((host) => (
                                                    <tr key={item.id + '|' + host.ip} className={host.status === 'accepted' ? 'ghost-text' : ''}>
                                                        <StatusCheckbox id={item.id + '|' + host.ip} checkedItems={checkedItems} onCheckboxChange={handleCheckboxChange} />
                                                        <td className="status-text">
                                                            {camelCase(host.status)}
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>{host.ip}</td>
                                                        <td>{host.domains.join(', ')}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                ))}
                                        </React.Fragment>))}
                                </tbody>
                                <tfoot className="table-light">
                                </tfoot>
                            </table>
                            {selectedRowData && (
                                <VulnerabilityModal isOpen={isModalOpen} onClose={closeModal} cve={selectedRowData} />)}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Vulnerabilties;
