export function camelCase(text) {
    if(text === 'ip') return 'IP';
    const words = text.split("_");
    return words.map((word, index) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + ' ';
    }).join("");
}

export function showIssues(count) {
    if (count < 0) {
        return "Invalid issue count";
    } else if (count === 0) {
        return "No new issues";
    } else if (count === 1) {
        return "1 new issue";
    } else {
        return `${count.toLocaleString()} new issues`;
    }
}

// per Rob 07-24-2024
export function categorizeScore(score) {
    if (score <= 200) {
        return "Very Low";
    } else if (score <= 400) {
        return "Low";
    } else if (score <= 600) {
        return "Medium";
    } else if (score <= 800) {
        return "High";
    }
    return "Critical";
}

// per Rob 07-24-2024
export function chartColor(value) {
    // Clamp the value between 0 and 100, then scale it to a range of 0 to 1000
    const score = Math.max(0, Math.min(100, value)) * 10;

    if (score <= 200) {
        return "#52c8e8";
    } else if (score <= 400) {
        return "#76c505";
    } else if (score <= 600) {
        return "#ffcd42";
    } else if (score <= 800) {
        return "#fe7b07";
    }
    return "#da1232";
}

export function bgColor(value) {
    return `style="background-color: ${chartColor(value)};"`;
}


export function showDate(value) {
    try {
        const date = new Date(value); // it's a UTC string need to convert
        const formattedDate = new Intl.DateTimeFormat(navigator.language, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        }).format(date);

        return formattedDate;
    }
    catch (error) {
        return value;
    }
}

