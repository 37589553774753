import React, { useState, useEffect } from "react";
import { chartColor, showDate } from './utils/helpers';
import Layout from "./Layout";

const Badge = ({ score }) => {
    const badgeStyle = {
        backgroundColor: chartColor(score / 10),
    };
    return (<span className="badge centered-badge" style={badgeStyle}>{score}</span>);
}

function sendCompanyRefresh(company_id) {
    console.log('Send Company Refresh', company_id);
}


const Company = ({ data, onSelectCompany }) => {

    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

    const sortedData = React.useMemo(() => {
        const sortableItems = [...data];
        if (sortConfig.key !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [data, sortConfig]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getHeaderClassName = (className, key) => {
        if (!sortConfig.key) return '';
        return className + ' ' + (sortConfig.key === key ? sortConfig.direction : '');
    };


    return (
        <>
            <Layout showNav={false} />
            <div className="page-content">
                <div className="container-fluid">
                    <h3>Managed Companies</h3>
                    <br />
                    <div className="table-responsive company-table">
                        <table className="table table-striped align-middle mb-0 company-table" sstyle="height:100%;width:75%; overflow: hidden;">
                            <thead>
                                <tr>
                                    <th onClick={() => requestSort('name')} className={getHeaderClassName('left-cell', 'name')}>Company</th>
                                    <th onClick={() => requestSort('website')} className={getHeaderClassName('left-cell', 'website')}>Website</th>
                                    <th onClick={() => requestSort('date_updated')} className={getHeaderClassName('centered-cell', 'date_updated')}>Last Updated</th>
                                    <th onClick={() => requestSort('risk_score')} className={getHeaderClassName('centered-cell', 'risk_score')}>Risk Score</th>
                                    <th className="centered-cell">Update</th>
                                    <th className="centered-cell">View</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedData && sortedData.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            {item.name}
                                            {item.ransomware_count > 0 && (<>&nbsp;<img width="16" height="16" src="/assets/images/ransomware.png" alt="Yes" /></>)}
                                        </td>
                                        <td>{item.website}</td>
                                        <td className="centered-cell">
                                            {showDate(item.date_updated)}
                                        </td>
                                        <td className="centered-cell">
                                            <Badge score={item.risk_score} />
                                        </td>
                                        <td className="centered-cell">
                                            <button className="fs-18 text-truncate bg-light rounded text-center border-0"
                                                onClick={(event) => sendCompanyRefresh(item.company_id)}>
                                                <i className="fa fa-refresh" aria-hidden="true"></i>
                                            </button>
                                        </td>
                                        <td className="centered-cell">
                                            <button className="fs-18 text-truncate bg-light rounded text-center border-0"
                                                onClick={(event) => onSelectCompany(item)}>
                                                <i className="mdi mdi-arrow-right text-dark align-middle"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Company;