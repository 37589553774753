import React from 'react';

const helpTable = {
    'breached_credentials': 'TARA CTA reviews records from public data breaches to identify any user credentials that match your organization\'s email naming standards. Malicious players can leverage credentials for account takeovers.  Ensure that any users who appear on the list have updated passwords and/or implemented multi-factor authentication to protect accounts.',
    'registered_domains': 'Because domains are used to set up email and website operations, it is important to be aware of newly assigned domain certificates to verify that are linked to your organization. Verifying that certificates were assigned your organization helps identify sites that may not have legitimate use and could be leveraged by cybercriminals to impersonate your organization. Marking legitimate sites as "Fixed" will avoid having them appear in future queries.',
    'spoofed_domains': 'Adversaries can purchase similar domains to imitate legitimate domains used by your organization, which are often used in phishing campaigns to steal credentials or information from unsuspecting users. The best course of action when false domains are identified is to block these domains in your organization’s email server (google, exchange) spam filter. Marking legitimate domains as “Fixed” will avoid having them appear in future queries.',
    'email_security': 'This section highlights discrepancies in Domain Name System (DNS) and Authentication mechanisms used by email systems. Having these items configured properly ensures that emails come from a trusted source, which helps prevent email spoofing, phishing attacks, and ensures higher delivery rate (less risk of being blocked or sent to spam) to external sources.',
    'expired_certificates': 'Security certificates provide assurance that users are connecting to reliable and trusted internet resources.  They are essential for establishing secure connections over the internet, protecting both the confidentiality and integrity of sensitive data exchanged between users, applications, and websites. When certificates expire, the protections they provide are no longer in place.',
    'open_ports': 'The firewall provides the first line of defense between your organization and the internet.  When ports are open, traffic is allowed to flow into your organization. To maintain proper security, ensure only necessary ports are open. Marking legitimate ports as “Fixed” will avoid having them appear in future queries.',
    'databases': 'Externally visible databases have the potential to expose organization data to unauthorized users. To maintain proper security, ensure only authorized databases accessible from the internet.  Marking legitimate databases as "Fixed" will avoid having them appear in future queries.',
    'remote_desktop_services': 'Remote access services are tools or technologies that enable users to access computer systems or networks from a remote location. These services facilitate remote management, troubleshooting, and collaboration, allowing users to interact with resources located elsewhere without the need for physical presence. For proper security, it is important to verify that all remote access services are configured properly (securely) and are valid for use in the organization. Marking legitimate services as “Fixed” will avoid having them appear in future queries.',
    'vuln': 'Software security gaps, commonly referred to as vulnerabilities, are at the root of most data breaches.  TARA CTA collects vulnerabilities, compares them against evolving cyber threats, and leverages A/I machine learning to assign each with a unique risk score.  Risk scores range from 0-1000 and the higher the score, the more risk a vulnerability represents to your organization.  Prioritize remedition activities based on score and ensure that all vulnerabilities with a score between 650-1000 are addressed with high priority.',
    'exploited_by_ransomware': 'Ransomware is software employed by adversaries to lock up (encrypt) the data used by organizations, ultimately making it unusable without access to the keys required to unlock it. Any software vulnerabilities listed in this section have proven associations with Ransomware and should be addressed with extreme priority.'
};

const Avatar = ({ topic, count,children }) => {

    const helptext = helpTable[topic];
    if (helptext === null) {
        helptext = topic;
    }

    if (count === 0) {
        return (<div class="avatar-sm">
            <div className="avatar-title" styles="background-color:transparent;">
                <i className="avatar-icon ri-checkbox-circle-fill fs-36 mb-0 text-success" title={helptext} />
                {children}
            </div>
        </div>);
    }
    return (<div class="avatar-sm">
        <div className="avatar-title">
            <i className="avatar-icon mdi mdi-alert fs-36 mb-0 text-danger" title={helptext} />
            {children}
        </div>
    </div>);
}

export default Avatar;

//             <i data-help-id={topic} className="avatar-icon mdi mdi-alert fs-36 mb-0 text-danger" data-trigger="click" data-toggle="tooltip" data-placement="top" title={helptext} />
// 
