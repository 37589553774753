import React from "react";
import SummaryItem from "./SummaryItem";

const Topics = ["email_security", "expired_certificates", "open_ports", "databases", "remote_desktop_services"];

const AttackSurface = (props) => (
    <div className="card">
        <div className="card-header">
            <h4 className="card-title">{props.data.name}</h4>
            <h4 className="card-title card-title-lg mb-0">Attack Surface</h4>
        </div>
        <div className="card-body pb-0">
            <div className="row row-cols-xxl-4 row-cols-xl-2 row-cols-1 mb-25 border-bottom">
                {Topics.map(topic => (
                    <>
                        <React.Fragment key={topic}>
                            <SummaryItem data={props.data} count={props.data.counts[topic]} topic={topic} />
                        </React.Fragment>
                    </>
                ))}
            </div>
        </div>
    </div>
);

export default AttackSurface;
