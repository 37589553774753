import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Layout from "../Layout";

const Logout = ({ onLogout }) => {
    const navigate = useNavigate();

    useEffect(() => {
        onLogout();
        navigate('/');
    }, [onLogout, navigate]);

    return (
        <>
            <Layout showNav={false} />
            <div class="page-content">
                <div class="container-fluid">
                    <h2>User Logout</h2>
                    <p>
                        Please close your browser window now.
                    </p>
                </div>
            </div>
        </>
    );
};

export default Logout;