import SummaryItem from "./SummaryItem";
const Topics = ["breached_credentials", "registered_domains", "spoofed_domains"];

const DigitalRiskProtection = (props) => {

    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">{props.data.name}</h4>
                <h4 className="card-title card-title-lg mb-0">Digital Risk Protection</h4>
            </div>
            <div className="card-body pb-0">
                <div class="row mt-4">
                    <div data-simplebar data-simplebar-auto-hide="false" data-simplebar-direction="rtl" className="zelo23">
                        {Topics.map(topic => (<SummaryItem data={props.data} count={props.data.counts[topic]} topic={topic} />))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DigitalRiskProtection;
