import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import restHandler from './apiClient';

import './App.css';
import Dashboard from './Dashboard';
import Company from "./Company";
import EULA from './EULA'

import Login from './auth/Login'
import Logout from './auth/Logout'


const App = () => {
    const [eulaAccepted, setEulaAccepted] = useState(!false);
    const [authenticated, setAuthenticated] = useState(false);
    const [company, setCompany] = useState(null);
    const [companies, setCompanies] = useState(null);
    const [error, setError] = useState(null);

    const selectCompany = (item) => {
      console.log('Select Company',item);
      setCompany(item)
    }
    
    const handleLogin = () => {
      setAuthenticated(true);
    };
  
    const handleLogout = () => {
      setAuthenticated(false);
      setCompanies(null);
      setCompany(null);
    };
  

    const handleEulaAccept = () => {
      setEulaAccepted(true);
    };

    useEffect(() => {
      if(!authenticated) return;
      const fetchData = async () => {
        try {
            const result = await restHandler('GET', '/api/companies');
            setCompanies(result);
        } catch (error) {
            setError(error.message || 'Error fetching data');
        }
    };

    fetchData();

    },[authenticated])

    const ConditionalRoute = ({ authenticated, eulaAccepted, company, companies, setCompany, setEulaAccepted, setAuthenticated }) => {
      if (!authenticated) return <Login onLogin={() => setAuthenticated(true)} />;
      if (!eulaAccepted) return <EULA onAccept={() => setEulaAccepted(true)} />;
      if (company) return (<Dashboard company={company} />);
      if (companies) return (<Company data={companies} onSelectCompany={(item) => setCompany(item)} />);
      return <></>;
    };

    return (
      <Router>
          <Routes>
              <Route path="/" element={<ConditionalRoute
                  authenticated={authenticated}
                  eulaAccepted={eulaAccepted}
                  company={company}
                  companies={companies}
                  setCompany={setCompany}
                  setEulaAccepted={setEulaAccepted}
                  setAuthenticated={setAuthenticated}
              />} />
              <Route path="eula" element={<EULA onAccept={() => setEulaAccepted(true)} />} />
              <Route path="logout" element={<Logout onLogout={() => {
                  setAuthenticated(false);
                  setCompany(null);
              }} />} />
              {/* Add other routes here as needed */}
          </Routes>
      </Router>
  );
};

export default App;
