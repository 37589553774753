import React, { useState } from 'react';
import Layout from '../Layout';
import '../Layout.css'
const apiHost = window.env.API_HOST;

const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(apiHost + '/token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    username,
                    password,
                }),
            });

            if (!response.ok) {
                throw new Error('Invalid credentials');
            }

            const data = await response.json();
            localStorage.setItem('token', data.access_token);
            onLogin();
        } catch (error) {
            setError(error.message);
        }
    };


    return (
        <>
            <Layout showNav={false} />
            <div className="container-fluid">
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="username">Username:</label>
                    <input type="text" id="username" name="username" value={username} required onChange={(e) => setUsername(e.target.value)} /><br /><br />
                    <label htmlFor="password">Password:</label>
                    <input type="password" id="password" name="password" value={password} required onChange={(e) => setPassword(e.target.value)} /><br /><br />
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <button type="submit">Login</button>
                </form>
                <br />
                <a href="/auth/forgot-password">Forgot Password</a>
            </div>
        </>
    );
};

export default Login;
