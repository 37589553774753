import React, { useEffect, useState } from 'react';
import restHandler from './apiClient';
import ApexCharts from 'apexcharts';
import { chartColor } from './utils/helpers';


export const CompanySectorChart = ({ data }) => {
    useEffect(() => {
        // Extracting data from props
        const companyData = data.company_score;
        const sectorData = data.sector_score;
        const dates = data.dates;

        // Chart options
        const options = {
            series: [
                {
                    name: 'Company',
                    type: 'bar',
                    data: companyData,
                },
                {
                    name: 'Sector',
                    type: 'line',
                    data: sectorData,
                },
            ],
            chart: {
                height: 400,
                type: 'bar',
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    columnWidth: '27%',
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                width: [2, 4],
            },
            markers: {
                size: 4,
            },
            xaxis: {
                labels: {
                    rotate: -45,
                },
                categories: dates,
                tickPlacement: 'on',
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (value) {
                        return value !== undefined ? `${value.toFixed(0)} points` : value;
                    },
                },
            },
            legend: {
                show: false,
            },
            fill: {
                colors: [
                    function ({ value }) {
                        return chartColor(value / 10);
                    },
                ],
            },
        };

        // Initialize the chart
        const chart = new ApexCharts(document.querySelector("#column_rotated_labels"), options);
        chart.render();

        // Cleanup function to destroy the chart when the component unmounts
        return () => {
            chart.destroy();
        };
    }, [data]); // Dependency array ensures effect runs when data changes

    return (
        <div className="chart-container">
            <div id="column_rotated_labels"></div>
        </div>
    );
};

export const SectorAverage = (props) => {

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await restHandler('GET', '/api/company_compare/135015');
                setData(result);
            } catch (error) {
                setError(error.message || 'Error fetching data');
            }
        };

        fetchData();
    }, []);

    if (!data) {
        return <div class="spinner-border" role="status">
            <span class="sr-only"></span>
        </div>
    }

    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">{props.data.name}</h4>
                <h4>Compared to {data.company.sector} Average</h4>
            </div>
            <div className="card-body pb-0">
                <div id="column_rotated_labels" data-colors='["--tb-dark", "--tb-info"]' className="apex-charts" dir="ltr">
                    <CompanySectorChart data={data} />
                </div>
            </div>
        </div>);
}


export default SectorAverage;
