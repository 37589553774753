import React, { useEffect, useState } from 'react';
import restHandler from './apiClient';

const IntelCard = ({ title, value }) => (
    <div class="col">
        <div class="card card-body bg-light bg-gradient sync-height">
            <div class="d-flex mb-4 align-items-center">
                <div class="flex-shrink-0">
                    <i class="bx bx-calendar fs-30 mr-10"></i>
                </div>
                <div class="flex-grow-1 ms-2">
                    <h5 class="card-title mb-1 fs-22">{title}</h5>
                </div>
            </div>
            <h6 class="mb-1 fs-30 text-info">{value}</h6>
        </div>
    </div>
)

const VulnerabilityModal = ({ isOpen, onClose, cve }) => {

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await restHandler('GET', '/api/svintel/' + cve);
                setData(result);
            } catch (error) {
                setError(error.message || 'Error fetching data');
            }
        };

        fetchData();
    }, [cve]);


    if (!isOpen) return null; // Don't render if the modal is closed

    if (!data) {
        return <div class="spinner-border" role="status">
            <span class="sr-only"></span>
        </div>
    }

    const orpheus = data.orpheus;
    const alert_class = orpheus.exploited_in_wild ? 'alert-danger' : 'alert-info';

    return (
        <div className="modal-overlay modal-dialog modal modal-xl">
            <div className="modal-content border-top-primary">
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                <div class="modal-header2">
                    <div class="row">
                        <div class="col-xl-11" styles="padding:15px 30px;">
                            <h5 class="modal-title fs-30" id="widespreadModalLabel"><i class="ri-bug-2-fill fs-30 mr-10"></i>
                                {data.id}
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="alert alert-dark alert-outline" role="alert">
                        <h3>Summary</h3>
                        <p class="fs-18">{data.description}</p>
                    </div>
                    <div className={`alert $(alert_class) alert-danger alert-dismissible alert-label-icon label-arrow fade show`} role="alert">
                        <i className="ri-alert-line label-icon fs-1"></i>
                        <span class="fs-3"><strong>Exploitation Status</strong> -
                            {data.orpheus.exploitation_status}
                        </span>
                    </div>
                    <div className="alert alert-dark alert-outline fs-18" id="two-col">
                        <div className="row row-cols-xxl-3 row-cols-lg-3 row-cols-1">
                            <IntelCard title="Last Updated on" value={orpheus.last_updated} />
                            <IntelCard title="Ransomware" value={orpheus.ransomware_related} />
                            <IntelCard title="Exploit in Wild" value={orpheus.exploited_in_wild} />
                            <IntelCard title="Likelihood of Exploit" value={orpheus.exploit_probablity} />
                            <IntelCard title="NVD Information" value="https://nvd.nist.gov/vuln/detail/" />
                        </div>
                    </div>
                </div>
                <button className="btn btn-primary btn-label w-lg" onClick={onClose}>
                    <i className="ri-close-line label-icon align-middle fs-16 me-2"></i>
                    Close
                </button>
            </div>
        </div>
    );
};

export default VulnerabilityModal;