import React from "react";
import { Link, Outlet } from 'react-router-dom';
import './Layout.css'
//import logo from '/assets/images/tara-logo.png';

const Layout = ({showNav}) => {
    return (
        <div>
            <nav className="top-nav">
                <div className="logo-container">
                    <Link to="/">
                        <img src='/assets/images/tara-logo.png' alt="Logo" className="logo" />
                    </Link>
                </div>
                {showNav && (
                <ul className="nav-list">
                    <li className="nav-item"><Link to="/eula">EULA</Link></li>
                    <li className="nav-item"><Link to="/logout">Logout</Link></li>
                </ul>
                )}
                </nav>
            <div className="content">
                <Outlet />
            </div>
        </div>
    );
};

export default Layout;