import React, { useEffect } from 'react';
import ApexCharts from 'apexcharts';
import { categorizeScore, chartColor } from './utils/helpers';


const Overview = (props) => {
    useEffect(() => {
        const options = {
            series: [props.data.risk_score / 10],
            chart: {
                height: 300,
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        value: {
                            show: true,
                            fontSize: '55px',
                            offsetY: -10,
                            color: '#000000',
                        },
                        name: {
                            fontSize: '0px',
                            offsetY: 36,
                            color: '#ffcd42',
                        },
                        total: {
                            show: true,
                            label: categorizeScore(props.data.risk_score),
                            formatter: function () {
                                return props.data.risk_score;
                            },
                        },
                    },
                    hollow: {
                        size: '55',
                    },
                },
            },
            labels: ['Medium'],
            colors: [chartColor(props.data.risk_score / 10)],
        };

        const chart = new ApexCharts(document.querySelector("#basic_radialbar"), options);
        chart.render();

        // Cleanup chart on component unmount
        return () => {
            chart.destroy();
        };
    }, [props.data.risk_score]);

    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">{props.data.name}</h4>
                <h4 className="card-title card-title-lg mb-0">Overall External Risk</h4>
            </div>
            <div className="card-body pb-0">
                <div id="basic_radialbar"></div>
            </div>
        </div>
    );
};

export default Overview;
