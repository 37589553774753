import React, { useEffect, useState } from 'react';
import restHandler from './apiClient';

import Layout from './Layout';
import Overview from './Overview';
import DigitalRiskProtection from './DigitalRiskProtection';
import AttackSurface from './AttackSurface';
import SectorAverage from './SectorAverage';
import Vulnerabilties from './Vulnerabilties';

const Dashboard = ({company}) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await restHandler('GET', '/api/company_summary/' + company.company_id);
                setData(result);
            } catch (error) {
                setError(error.message || 'Error fetching data');
            }
        };

        fetchData();
    }, [company.company_id]);

    if (!data) {
        return <div class="spinner-border" role="status">
            <span class="sr-only"></span>
        </div>
    }

    return (
        <>
        <Layout showNav={true} />
        <div className="page-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-3">
                        <Overview data={data} />
                        <DigitalRiskProtection data={data} />
                    </div>
                    <div className="col-xl-9">
                        <AttackSurface data={data} />
                        <SectorAverage data={data} />
                    </div>
                    <Vulnerabilties data={data} />
                </div>
            </div>
        </div>
        </>
    );
}

export default Dashboard;
