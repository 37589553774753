export const StatusMenu = ({ topic, onClick }) => {
  return (
    <>
      <button type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-light btn-sm threedots"> <i className="ri-more-2-fill fs-18"></i> </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
        <li className="text-left"><a className="dropdown-item" href={`/export/csv/${topic}`} rel="noreferrer" target="_blank">Export Data as CSV</a></li>
        <li className="text-left"><a className="dropdown-item" href={`/export/json/${topic}`} rel="noreferrer" target="_blank">Export Data as JSON</a></li>
        <li className="text-left"><a className="dropdown-item is-disabled">Mark As</a></li>
        <ul className="sub-ul">
          <li className="text-left"><a className="dropdown-item" href="#" onClick={(event) => onClick(event, 'clear')}>-- Clear --</a></li>
          <li className="text-left"><a className="dropdown-item" href="#" onClick={(event) => onClick(event, 'accepted')}>Accepted</a></li>
          <li className="text-left"><a className="dropdown-item" href="#" onClick={(event) => onClick(event, 'fixed')}>Fixed</a></li>
          <li className="text-left"><a className="dropdown-item" href="#" onClick={(event) => onClick(event, 'being_fixed')}>Being Fixed</a></li>
          <li className="text-left"><a className="dropdown-item" href="#" onClick={(event) => onClick(event, 'awaiting_approval')}>Awaiting Approval</a></li>
        </ul>
      </ul>
    </>
  );
};

export const StatusCheckbox = ({ id, checkedItems, onCheckboxChange }) => {
  return (
    <>
      <td valign="middle" className="status-toggle">
        <div className="form-check">
          <input className="form-check-input childCheckbox"
            type="checkbox"
            value={id}
            name="itemids"
            id="itemids"
            checked={!!checkedItems[id]}
            onChange={(event) => onCheckboxChange(event,id)}
          />
          <label className="form-check-label"></label>
        </div>
      </td>
    </>);
};
